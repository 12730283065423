.image {
    opacity:0;
    transition:opacity 0.5s;
    width:100%;
}
.image.shadow {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}
.image.loaded {
    opacity:1;
}
