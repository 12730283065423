.app-bar-title {
    flex-grow:1;
    cursor:pointer;
}
.app-menu-btn {
    margin-right:16px;
}
.city {
    color:#aaa;
}
.spacer {
    width:100%;
    height:16px;
}
.page-infos {
    text-align:right;
    font-size:0.8em;
}
.clickable {
    cursor:pointer;
}
.td-small {
    width:50px;
}
.menu {
    width:250px;
    margin-top:56px;
}
.text-input.MuiTextField-root {
    margin-bottom:8px;
    width:100%
}
.film-poster {
    position: relative;
    padding-bottom: 133%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    background-color: #333;
}
.film-poster-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow:hidden;
}
.relative {
    position:relative;
}
.poster-chip {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width:100%;
    text-align:right;
}
.film-poster.shadow {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}
