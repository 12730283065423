.router-route {
    opacity:0;
    transition:opacity 0.5s;
    width:100%;
}
.router-route.router-current{
    position:relative;
    opacity:1;
    z-index:2;
    animation: rise 0.5s;
}
@keyframes rise {
    from {opacity:0;}
    to {opacity:1;}
}
.router-route.router-current.router-samecomponent {
    animation: none;
}
.router-route.router-previous{
    position: fixed;
    z-index:1;
    opacity:0;
    height:100%;
}
.router {
    position:relative;
    top:0;
    left:0;
    width:100%;
    margin-top: 24px;
    overflow-x: hidden;
}
.router-route>div:first-child {
    min-height:calc( 100vh - 3vw - 30px );
    padding-bottom:60px;
}
