.upload-zone {
    width:100%;
    cursor:pointer;
}
.upload-zone-inner {
    padding:24px;
}
.upload-zone.hover {
    background-color: #eee;
}
.upload-zone .notice {
    color:#888;
    font-size:0.8em;
}
