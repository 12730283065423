.footer {
    position:relative;
    width:100%;
    color:#FFF;
    padding-bottom:50px;
}
.footer .background {
    position:absolute;
    top:0;
    width:10000px;
    left: calc( 50% - 5000px );
    bottom:0;
    background-color:#3f51b5;
}
.footer .content {
    width:100%;
    padding-top:30px;
}
.footer .plan {
    text-align:center;
}
.footer-liste {
    display: inline-block;
    list-style: none;
    margin:15px 0;
    padding:0;
    width:max-content;
    text-align:left;
}
.footer-liste span {
    cursor:pointer;
}
